import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

import Container from "./Container"

const overlayVariables = {
  backgroundColor: `${theme.themeColors.themeTransparentDark}`,
  textColor: `${theme.themeColors.themeTextEmphasizedInverse}`,
  linkIconColor: `${theme.themeColors.themeAction}`
}

const Overlay = styled.div`
  background-color: ${overlayVariables.backgroundColor};
  color: ${overlayVariables.textColor};
  padding-bottom: ${theme.spaces.l};
  padding-top: ${theme.spaces.xl};
  -webkit-clip-path: polygon(0 0%, 100% 35%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0%, 100% 35%, 100% 100%, 0% 100%);
`

const OverlayContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`

const Heading = styled.h1`
  font-size: ${theme.fontSizes.fontSize2XL};
  flex-basis: 100%;
  margin: ${theme.margin.stackS};
  max-width: 100%;

  ${theme.media.md`
    flex-basis: auto;
    flex-grow: 1;
  `}
`
  
const SubHeading = styled.p`
  flex-basis: 85%;
  margin: ${theme.margin.stackS};
  max-width: 85%;

  ${theme.media.lg`
    flex-basis: 100%;
    margin-top: 0;
    max-width: 100%
  `}
`

const StyledLink = styled(Link)`
  align-self: center;
  color: ${overlayVariables.textColor};
  font-weight: 500;
  margin-top: ${theme.spaces.s};
  text-decoration: none;
  
  ::after {
    content: "→";
    color: ${overlayVariables.linkIconColor};
    font-size: ${theme.fontSizes.fontSizeXL};
    padding: ${theme.padding.insetS};
    vertical-align: middle;
  }

  ${theme.media.md`
    margin-top: 0;
  `}
`
export default props => {
  return (
    <Overlay>
      <OverlayContainer>
        <SubHeading dangerouslySetInnerHTML={{__html: props.subheading}}></SubHeading>
        <Heading dangerouslySetInnerHTML={{__html: props.heading}}></Heading>
        {
          props.linkText ?
            <StyledLink to={props.linkUrl}>{props.linkText}</StyledLink> :
            null
        }
      </OverlayContainer>
    </Overlay>
  )
}