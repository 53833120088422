import React, { Component, Fragment } from "react"
import "whatwg-fetch"
import styled, { ThemeProvider } from "styled-components"
import theme from "../Theme"

import Container from "../components/Container"
import Main from "../components/Main"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Loader from "../components/Loader"
import CardGrid from "../components/CardGrid"
import buildCardGridArray from "../scripts/buildCardGridArray"
import NoPieces from "../components/NoPieces"

const Heading = styled.h1`
`

const StyledCardGrid = styled(CardGrid)`
    margin: ${theme.margin.stack};
`

class categoryPieces extends Component {
    componentDidMount() {
        this.props.getData(this.props.match.params.slug)
    }

    render() {
        return(
            <ThemeProvider theme={{ mode: "main" }}>
                <div className="App">
                    <Header />
                    <div className="flex-grow">
                        <Loader loading={this.props.loading}>
                            <Main>
                                <Container>
                                    {
                                        this.props.pieces.length > 0 ?
                                            <Fragment>
                                                <Heading>{this.props.name}</Heading>
                                                <StyledCardGrid 
                                                    cards={buildCardGridArray(this.props.pieces)}
                                                    columns={
                                                        {
                                                        sm: 2,
                                                        md: 2,
                                                        lg: 3,
                                                        xl: 4
                                                        }
                                                    } />
                                            </Fragment>
                                        : <NoPieces />
                                    }
                                </Container>
                            </Main>
                        </Loader>
                    </div>
                    <Footer />
                </div>
            </ThemeProvider>
        )
    }
}

export default categoryPieces