/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import "whatwg-fetch"

// themeing and images
import styled, { ThemeProvider } from "styled-components"
import theme from "../Theme"

// components
import Button from "../components/Button"
import Container from "../components/Container"
import Footer from "../components/Footer"
import Header from "../components/Header"

const Error = styled.div`
  background-color: ${theme.themeColors.themeTransparentDark};
  color: ${theme.themeColors.themeTextCommonInverse};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${theme.spaces.xxxl};
  text-align: center;

  h1 {
    margin: 0;
  }
`

const StyledButton = styled(Button)`
  margin: ${theme.margin.stack};
`

export default props =>
  <ThemeProvider theme={{ mode: "main" }}>
    <div className="App">
      <Header />
        <Error>
          <Container>
          <h1>Page Not Found</h1>
          <p>Sorry, we could not find the page you requested.</p>
          <StyledButton 
            inline
            text="Take Me Home"
            url="/"
            className={props.className}
          />
          </Container>
        </Error>
      <Footer />
    </div>
  </ThemeProvider>
