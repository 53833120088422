export default address => {
  // // find the commas
  // let firstCommaIndex = address.indexOf(",")
  // let lastCommaIndex = address.lastIndexOf(",")

  // // pull out the street based on first comma
  // let streetIndex = firstCommaIndex > 0 ? firstCommaIndex : address.length
  // let street = address.slice(0, streetIndex)
  
  // // pull out the city state zip based on first and last commas
  // // if no first or last comma then set to undefined
  // let cityStateZip = firstCommaIndex > 0 && lastCommaIndex > 0 ? address.slice((streetIndex + 2), lastCommaIndex).split(" ") : null
  // // split up the city, state and zip
  // let city, state, zip
  // if(cityStateZip) {
  //   city = cityStateZip[0].slice(0, (cityStateZip[0].length - 1))
  //   state = cityStateZip[1]
  //   zip = cityStateZip[2]
    
  //   let AddressLineOne = street
  //   let AddressLineTwo = `${city}, ${state} ${zip}`
    
  //   return `${AddressLineOne}, ${AddressLineTwo}`

  // } else {
  //   return street
  // }

  let addressArray = address.split(",");
  let locationTitle, street, city, stateZip;

  if (addressArray.length > 4) {
    locationTitle = addressArray[0];
    street = addressArray[1];
    city = addressArray[2];
    stateZip = addressArray[3];
  } else {
      street = addressArray[0];
      city = addressArray[1];
      stateZip = addressArray[2];
  }

  let formattedAddress = {
    locationTitle: locationTitle,
    street: street,
    city: city,
    stateZip: stateZip
  }

  return formattedAddress
}
