import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

import Address from "./Address"

const InfoPanel = styled.div`
    background-color: ${ theme.themeColors.themePrimary };
    border-radius: 2px;
    color: ${ theme.themeColors.themeTextCommonInverse };
    max-width: 80vw;
    width: 300px;
    padding: ${theme.spaces.base};
    position: relative;

    &::after {
        border: 12px solid transparent;
        border-top-color: ${ theme.themeColors.themePrimary };
        content: "";
        display: block;
        position: absolute;
        top: 99%;
        left: 50%;
        transform: translateX(-50%);
    }
`

const Heading = styled.h1`    
    font-size: ${ theme.fontSizes.fontSize };
    margin-top: 0;
`

// const Address = styled.p`
//     font-size: ${ theme.fontSizes.fontSizeS };
//     margin: ${theme.margin.stackS};

//     p {
//         margin: 0;
//     }
// `

const StyledLink = styled(Link)`
  color: ${theme.themeColors.themeTextCommonInverse};
  display: block;
  font-size: ${theme.fontSizes.fontSize};
  margin: ${theme.margin.stackS};
  text-decoration: none;
  
  ::after {
    content: "→";
    color: ${theme.themeColors.themeTextAction};
    font-size: ${theme.fontSizes.fontSize2XL};
    font-weight: 800;
    margin-left: ${theme.spaces.s};
    vertical-align: middle;
  }
`

const Close = styled(Link)`
  color: ${theme.themeColors.themeTextCommonInverse};
  float: right;
  font-size: ${theme.fontSizes.fontSizeS};
  margin-left: ${theme.spaces.s};
  text-decoration: none;
`

export default props =>
    <InfoPanel { ...props }>
        <Close to={ `/pieces/map` } aria-label="close info window">X</Close>
        { props.heading ? <Heading dangerouslySetInnerHTML={{__html: props.heading}}></Heading> : null }
        { props.address ? <Address address={props.address}></Address> : null }
        { props.linkText ? <StyledLink to={props.linkUrl}>{props.linkText}</StyledLink> : null}
    </InfoPanel>
