export default data => {
  let address = data.address ? data.address.address : '';
  let locationTitle = '', street = '', city = '', stateZip = '';

  if (address != '') {
    let addressArray = address.split(",");

    if (addressArray.length > 4) {
      locationTitle = addressArray[0];
      street = addressArray[1];
      city = addressArray[2];
      stateZip = addressArray[3];
    } else {
      street = addressArray[0];
      city = addressArray[1];
      stateZip = addressArray[2];
    }
  }

  let image;

  if (data) {
    if (data.card_img_src) {
      image = data.card_img_src
    }

    if (data.cardImage) {
      image = data.cardImage
    }
  }

  const cardObject = {
    id: data.id,
    url: `/piece/${data.id}`,
    heading: data.title,
    address: {
      locationTitle: locationTitle,
      street: street,
      city: city,
      stateZip: stateZip
    },
    distance: {
      value: "2.3",
      unit: "miles"
    },
    image: image
  }

  return cardObject
}