/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../../Theme"

import Address from "../../components/Address";
// import Button from "../../components/Button"
import Button from "../../components/MapButton"

import Container from "../../components/Container"
import HTMLRenderer from "../../components/HTMLRenderer"

const InfoSection = styled.div`
  background: ${theme.themeColors.themePrimary};
  background-color: ${theme.themeColors.themePrimary};
  color: ${theme.themeColors.themeTextCommonInverse};
  padding-bottom: ${theme.spaces.xl};
  padding-top: ${theme.spaces.l};
`

const InfoSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Info = styled.section`
  flex-basis: auto;
  width: 100%;

  ${theme.media.md`
    flex-basis: calc(50% - ${theme.spaces.l});
    margin: ${theme.margin.inlineL};
    width: calc(50% - ${theme.spaces.l});
  `};
`

const InfoHeading = styled.h1`
  margin-top: 0;
`

const InfoSubHeading = styled.h2`
  color: ${theme.themeColors.themeBase2};
  font-family: "Open Sans";
  font-size: ${theme.fontSizes.fontSizeL};
  font-weight: 500;
`

const InfoList = styled.ul`
  list-style-type: none;
  margin: ${theme.margin.stackXXS};
  padding: 0;
`

const InfoListItem = styled.li`
  display: inline-block;
  margin: ${theme.margin.inlineXXS};

  &:after {
    content: ",";
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`

const StyledLink = styled(Link)`
  color: ${theme.themeColors.themeTextCommonInverse};
  display: inline-block;
`

const InfoText = styled.p`
  margin: ${theme.margin.stackXXS};
`

const LocationInfo = styled.section`
  align-self: center;
  background-color: ${theme.themeColors.themePrimary2};
  flex-basis: auto;
  margin: ${theme.margin.stackXL};
  padding-bottom: ${theme.spaces.l};
  padding-left: ${theme.spaces.base};
  padding-right: ${theme.spaces.base};
  padding-top: ${theme.spaces.base};
  width: 100%;

  ${theme.media.md`
    flex-basis: 45%;
    flex-grow: 1;
    margin: 0;
    width: 45%;
  `};
`

const LocationData = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
`

const LocationAddress = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;

  ${theme.media.lg`
    flex-basis: 70%;
    margin-right: ${theme.spaces.base};
    width: 70%;
  `};
`

const LocationName = styled.p`
  color: ${theme.themeColors.themeBase2};
  font-size: ${theme.fontSizes.fontSizeL};
  margin: ${theme.margin.stackS};
`

// const Address = styled.p`
//   margin: ${theme.margin.stackS};
// `

const LocationAction = styled.div`
  flex-basis: 100%;
  margin: ${theme.margin.stack};
  width: 100%;

  ${theme.media.lg`
    flex-basis: 25%;
    margin: 0;
    width: 25%;
  `};
`

const LocationNotes = styled(HTMLRenderer)`
  flex-grow: 1;
  margin: ${theme.margin.stack};

  a {
    color: ${theme.themeColors.themeActionSecondary};
  }
`

const MapButton = styled(Button)`
  background-image: url("/map-icon-white.png");
  display: inline-block;
  padding-left: ${ theme.spaces.xl };
`

export default props => {
  return (
    <InfoSection>
      <Container>
        <InfoSectionContainer>
          {props.types.length > 0 || props.year || props.themes.length > 0 ? (
            <Info>
              <InfoHeading>At a Glance</InfoHeading>
              {props.types.length > 0 ? (
                <div>
                  <InfoSubHeading>Type</InfoSubHeading>
                  <InfoList>
                    {props.types.map(type => (
                      <InfoListItem key={type.term_id}>
                        <StyledLink to={
                          {
                            pathname: `/pieces/map`,
                            state: {
                              type: type.name
                            }
                          }
                        }>
                          {type.name}
                        </StyledLink>
                      </InfoListItem>
                    ))}
                  </InfoList>
                </div>
              ) : null}
              {props.year ? (
                <div>
                  <InfoSubHeading>Year</InfoSubHeading>
                  <InfoText>{props.year}</InfoText>
                </div>
              ) : null}

              {props.themes.length > 0 ? (
                <div>
                  <InfoSubHeading>Themes</InfoSubHeading>
                  <InfoList>
                    {props.themes.map(theme => (
                      <InfoListItem key={theme.term_id}>
                        <StyledLink to={
                          {
                            pathname: `/pieces/map`,
                            state: {
                              theme: theme.name
                            }
                          }
                        }>{theme.name}</StyledLink>
                      </InfoListItem>
                    ))}
                  </InfoList>
                </div>
              ) : null}
            </Info>
          ) : null}
          <LocationInfo>
            <InfoHeading>Location</InfoHeading>
            <LocationData>
              <LocationAddress>
                <LocationName>{props.locationName}</LocationName>
                {props.address ? (
                  <Address address={props.address} lat={props.lat} lng={props.lng} isLink></Address>
                ) : null}
              </LocationAddress>
              <LocationAction>
                <MapButton to={`/pieces/map/${props.buttonUrl}`}>Map</MapButton>
              </LocationAction>
            </LocationData>
            {props.locationNotes ? (
              <LocationNotes html={props.locationNotes} />
            ) : null}
          </LocationInfo>
        </InfoSectionContainer>
      </Container>
    </InfoSection>
  )
}
