/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"

const ImageBanner = styled.section`
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 42vw;
`

ImageBanner.defaultProps = {
  image: "https://publicart.onecolumbiasc.com/wp-content/uploads/2018/03/placeholder-image.jpg",
}

export default props =>
  <ImageBanner image={props.image} />