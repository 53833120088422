/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import Lightbox from 'react-images'
import styled from "styled-components"
import theme from "../../Theme"
import LazyLoad from 'react-lazyload'


const GalleryContainer = styled.div`
  margin: ${theme.margin.stackXL};
`

const GalleryHeading = styled.h1`
  font-size: ${theme.fontSizes.fontSize};
`

const GalleryImages = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GalleryImage = styled.div`
  display: inline-block;
  margin: ${theme.margin.inlineL};
  margin-top: ${theme.spaces.base};
`

const GalleryButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
`

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lightboxImages: [],
      lightboxIsOpen: false,
      currentImage: 0
    }
  }

  componentDidMount() {
    let gallery = this.props.piece.acf.gallery;
    let imgSrcArray = gallery.map(item => {
      let imgObj = {src: item.url}
      return imgObj
    })

    this.setState({ lightboxImages: imgSrcArray })
    
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)

  }

  closeLightbox() {
    this.setState({ lightboxIsOpen: false })
  }
  
  openLightbox(i) {
    this.setState(
      { 
        lightboxIsOpen: true,
        currentImage: i
      })
  }

  gotoNext() {
    this.setState({ currentImage: this.state.currentImage + 1})
  }

  gotoPrevious() {
    this.setState({ currentImage: this.state.currentImage - 1})
  }

  render () {
    return (
      <GalleryContainer>
        { this.props.gallery.length > 0 ?
            <div>
              <GalleryHeading>Images</GalleryHeading>
              <GalleryImages>
                <LazyLoad height={800} once offset={250}>
                  {
                    this.props.gallery.map((item, i) => (
                        <GalleryImage key={item.id}>
                          <GalleryButton
                            onClick={() => this.openLightbox(i)} 
                            tabIndex="0"><img src={item.sizes.card} alt={item.alt} /></GalleryButton>
                        </GalleryImage>
                      )
                    )
                  }
                  </LazyLoad>
              </GalleryImages>
            </div>
          : null
        }
        <Lightbox
            images={this.state.lightboxImages}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={this.closeLightbox}
        />
      </GalleryContainer>
    )
  }
}

export default Gallery
