import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

const Button = styled(Link)`
  background-color: ${ theme.themeColors.themeAction };
  background-repeat: no-repeat;
  background-position: left ${theme.spaces.l} center;
  background-size: auto ${theme.spaces.base};  
  border-radius: 2px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  color: ${ theme.themeColors.themeTextCommonInverse };
  font-weight: 600;
  padding-top: ${theme.spaces.s};
  padding-right: ${theme.spaces.l};
  padding-bottom: ${theme.spaces.s};
  padding-left: ${theme.spaces.l};
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${ theme.themeColors.themeAction2 };
  }

  &:active {
    background-color: ${ theme.themeColors.themeAction3 };
  }
`

export default props => 
  <Button {...props} />