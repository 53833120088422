import "babel-polyfill"
import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import Home from "./pages/home"
import Posts from "./pages/posts"
import PostPage from "./pages/postPage"
import Pieces from "./pages/pieces"
import PieceDetail from "./pages/pieceDetail"
import NotFound from "./pages/notFound"
import CategorizedPieces from "./pages/categoryPieces"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allPosts: [],
      posts: [],
      post: {},
      piece: {},
      userLocation: {},
      locations: [],
      themes: [],
      types: [],
      piecesFilters: {
        search: "",
        location: "",
        theme: "",
        type: ""
      },
      selectedMapPoint: "",
      piecesLoading: true,
      pieces: [],
      homePageData: {},
      spotlightPiece: {},
      featuredPosts: [],
      featuredPieces: [],
      homePageLoading: true,
      postLoading: true,
      postsLoading: true,
      pieceDetails: {},
      pieceDetailsLoading: true,
      totalPages: 0,
      categories: [],
      category: {
        name: "",
        pieces: []
      },
      categoriesLoading: false
    }

    this.getHomePageData = this.getHomePageData.bind(this)
    this.getPostData = this.getPostData.bind(this)
    this.getAllPosts = this.getAllPosts.bind(this)
    this.getPieceDetails = this.getPieceDetails.bind(this)
    this.getPiecesData = this.getPiecesData.bind(this)
    this.updatePiecesFilters = this.updatePiecesFilters.bind(this)
    this.getPiecesInCategory = this.getPiecesInCategory.bind(this)
    // this.getCategories = this.getCategories.bind(this)
  }

  getHomePageData() {
    const api = "https://publicart.onecolumbiasc.com/wp-json/wp/v2"
    // const api = "https://publicart.local/wp-json/wp/v2"
    this.setState({
      homePageLoading: true
    })
    // Fetch initial page data (home page settings - spotlight piece, featured posts and pieces)
    fetch(`${api}/pages/86`)
      .then(response => response.json())
      .then(json => {
        this.setState({
          homePageData: json,
        })
        // Spotlight Piece
        // Fetch and set state for the spotlight piece details using the id from the above page fetch
        fetch(`${api}/pieces/${json.acf.spotlight_piece.ID}?_embed`)
          .then(response => response.json())
          .then(json => this.setState({ spotlightPiece: json }))
          .catch((error) => {
            console.log(error)
          });

        //Featured Pieces
        //Get the ids for all featured pieces for a future fetch
        let featuredPiecesIds = json.acf.featured_pieces.map(
          piece => piece.feature_piece.ID
        )

        fetch(
          `${api}/pieces/?include[]=${featuredPiecesIds[0]}&include[]=${featuredPiecesIds[1]}&include[]=${featuredPiecesIds[2]}&_embed`
        )
          .then(response => response.json())
          .then(json => this.setState({ featuredPieces: json }))
          .then(() => {
            return this.setState({
              homePageLoading: false
            })
          }).catch((error) => {
            console.log(error)
          });

        // Featured Posts
        // Get the ids for all featured posts for a future fetch
        if (json.acf.featured_posts.length > 0) {
          let featuredPostsIds = json.acf.featured_posts.map(
            post => post.featured_post.ID
          )

          if (json.acf.featured_posts.length === 1) {
            fetch(
              `${api}/posts?include[]=${featuredPostsIds[0]}`
            )
              .then(response => response.json())
              .then(json => this.setState({ featuredPosts: json }))
              .catch((error) => {
                console.log(error)
              });
          }

          if (json.acf.featured_posts.length === 2) {
            fetch(
              `${api}/posts?include[]=${featuredPostsIds[0]}&include[]=${featuredPostsIds[1]}`
            )
              .then(response => response.json())
              .then(json => this.setState({ featuredPosts: json }))
              .catch((error) => {
                console.log(error)
              });
          }
        }
      }).catch((error) => {
        console.log(error)
      });
  }

  getPiecesData() {
    const api = "https://publicart.onecolumbiasc.com/wp-json/publicarttheme/v2"
    fetch(`${api}/pieces/`)
      .then(response => response.json())
      .then(json => this.setState({
        pieces: json,
        piecesLoading: false,
        locations: [...new Set(json.map(location => location.city))]
      })
      ).catch((error) => {
        console.log(error)
      });
  }

  getPostData(id) {
    this.setState({
      postLoading: true
    })
    fetch(
      `https://publicart.onecolumbiasc.com/wp-json/wp/v2/posts/${id}?_embed`
    )
      .then(response => response.json())
      .then(json =>
        this.setState({
          post: json,
          postLoading: false
        })
      )
      .catch(ex => console.log("parsing failed", ex))
  }

  getAllPosts() {
    this.setState({
      postsLoading: true
    })
    fetch(
      `https://publicart.onecolumbiasc.com/wp-json/wp/v2/posts/`
    )
      .then(response => response.json())
      .then(json =>
        this.setState({
          allPosts: json,
          postsLoading: false
        })
      )
      .catch(ex => console.log("parsing failed", ex))
  }

  getPieceDetails(id) {
    this.setState({
      pieceDetailsLoading: true
    })
    fetch(
      `https://publicart.onecolumbiasc.com/wp-json/wp/v2/pieces/${id}?_embed`
    )
      .then(response => response.json())
      .then(json =>
        this.setState({
          pieceDetails: json,
          pieceDetailsLoading: false
        })
      )
      .catch(ex => console.log("parsing failed", ex))
  }

  updatePiecesFilters(filters) {
    this.setState({
      piecesFilters: {
        ...filters
      }
    })
  }

  getPiecesInCategory(slug) {
    let categories = [];
    let category = [];

    this.setState({ categoriesLoading: true })

    // get categories
    fetch(`https://publicart.onecolumbiasc.com/wp-json/publicarttheme/v1/categories`)
      .then(response => response.json())
      .then(json => {
        categories = json.map(item => ({ id: item.term_id, slug: item.slug, name: item.name }))
        category = categories.find(item => item.slug === slug)

        if (category === undefined) {
          this.setState({ categoriesLoading: false })
          throw new Error('category does not exist');
        }

        return fetch(`https://publicart.onecolumbiasc.com/wp-json/publicarttheme/v1/pieces/${category.id}`)
      })
      .then(response => {
        return response.json()
      })
      .then(json => {
        this.setState({
          category: {
            name: category.name,
            pieces: json
          },
          categoriesLoading: false
        })
      })
      .catch(err => {
        if (err.message === 'category does not exist') {
          // intentionally aborted
        }
        else {
          throw err;
        }
      })
  }

  componentDidMount() {
    // get themes
    fetch("https://publicart.onecolumbiasc.com/wp-json/wp/v2/art_theme")
      .then(response => response.json())
      .then(json => {
        return this.setState({ themes: json })
      })
      .catch(ex => console.log("parsing failed", ex))

    // get types
    fetch("https://publicart.onecolumbiasc.com/wp-json/wp/v2/art_type")
      .then(response => response.json())
      .then(json => {
        return this.setState({ types: json })
      })
      .catch(ex => console.log("parsing failed", ex))

    // user location
    // if (!navigator.geolocation){
    //   return;
    // }
    // let success = (position) => {
    //   var latitude  = position.coords.latitude;
    //   var longitude = position.coords.longitude;
    //   var userLocation = {
    //     latitude,
    //     longitude
    //   }
    //   this.setState({ userLocation })
    // }
    // let error = () => {
    //   console.log("location error")
    // }
    // navigator.geolocation.getCurrentPosition(success, error);
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Home
                  {...props}
                  homePageData={this.state.homePageData}
                  spotlightPiece={this.state.spotlightPiece}
                  featuredPieces={this.state.featuredPieces}
                  featuredPosts={this.state.featuredPosts}
                  missionPosts={this.state.missionPosts}
                  loading={this.state.homePageLoading}
                  getHomePageData={this.getHomePageData}
                />
              )}
            />
            {/* <Redirect from="/w" to="/"/> */}
            <Route
              exact
              path="/posts"
              render={props => (
                <Posts
                  {...props}
                  getAllPosts={this.getAllPosts}
                  loading={this.state.postsLoading}
                  posts={this.state.allPosts} />
              )}
            />
            <Route
              path="/posts/:postid"
              render={props => (
                <PostPage
                  {...props}
                  post={this.state.post}
                  loading={this.state.postLoading}
                  getPostData={this.getPostData}
                />
              )}
            />
            <Route
              path="/collections/:slug"
              render={props => (
                <CategorizedPieces
                  {...props}
                  getData={this.getPiecesInCategory}
                  name={this.state.category.name}
                  pieces={this.state.category.pieces}
                  loading={this.state.categoriesLoading}
                />
              )}
            />
            <Route
              path="/pieces"
              render={props => (
                <Pieces
                  {...props}
                  pieces={this.state.pieces}
                  filters={this.state.piecesFilters}
                  selectedMapPoint={this.state.selectedMapPoint}
                  loading={this.state.piecesLoading}
                  search={this.state.piecesFilters.search}
                  locations={this.state.locations}
                  themes={this.state.themes.map(theme => theme.name)}
                  types={this.state.types.map(type => type.name)}
                  update={this.updatePiecesFilters}
                  getData={this.getPiecesData}
                />
              )}
            />
            <Route
              path="/piece/:pieceid"
              render={props => (
                <PieceDetail
                  {...props}
                  piece={this.state.pieceDetails}
                  loading={this.state.pieceDetailsLoading}
                  getPieceDetails={this.getPieceDetails}
                  update={this.updatePiecesFilters}
                />
              )}
            />

            <Route render={(props) => <NotFound {...props} />} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
