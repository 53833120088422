import React from "react"
import styled from "styled-components"
import theme from "../Theme"

import Container from "./Container"
import LogoLink from "./LogoLink"
import MainNavigation from "./MainNavigation"

const Header = styled.header`
  background-color: ${theme.themeColors.themePrimary2};
  z-index: 1;
`

const HeaderContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export default props =>
  <Header className={props.className}>
    {
      props.noContainer ? (
        <HeaderContainer>
          <LogoLink 
            url="/" 
            logoText="Columbia's Public Art Directory" 
            imageUrl="/One_Columbia_white.svg" 
            imageAltText="Public Art Directory Logo" />
          <MainNavigation />
        </HeaderContainer>
      )
      : (
        <Container>
          <HeaderContainer>
            <LogoLink 
              url="/" 
              logoText="Columbia's Public Art Directory" 
              imageUrl="/One_Columbia_white.svg" 
              imageAltText="Public Art Directory Logo" />
            <MainNavigation />
          </HeaderContainer>
        </Container>
      )
    }
  </Header>
    