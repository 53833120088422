/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

import HTMLRenderer from "./HTMLRenderer"
import Container from "./Container"
import LinkList from "./LinkList"

const postVariables = {
  titleColor: `${theme.themeColors.themeTextCommon}`,
  subTitleColor: `${theme.themeColors.themeTextEmphasized}`,
  subTitleFontSize: `${theme.fontSizes.fontSizeL}`
}

const Post = styled.article`
  margin-bottom: ${theme.spaces.xxl};
  margin-top: ${theme.spaces.xl};
`

const Heading = styled.h1`
  color: ${postVariables.titleColor};
`

const SubHeading = styled.p`
  color: ${postVariables.subTitleColor};
  font-size: ${postVariables.subTitleFontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const Content = styled(HTMLRenderer)`
  margin-bottom: ${theme.spaces.l};
`

const RelatedLinks = styled(LinkList)``

const EventDetails = styled.p`
  color: ${postVariables.subTitleColor};
  font-size: ${postVariables.subTitleFontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const LocationLink = styled.a`
  display: block;
  margin: ${theme.margin.stackXXS};
`

export default props =>
  <Container> {
    props.post.title ?
      <Post>
        <Heading dangerouslySetInnerHTML={{__html: props.post.title.rendered}}></Heading> 
        {
          props.post.acf.subtitle || props.post.acf.date ?
            props.post.acf.post_is_event ?
              (props.post.acf.post_is_event === "No" ?
                <SubHeading>{props.post.acf.subtitle}</SubHeading> :
                <EventDetails>{props.post.acf.date}, {props.post.acf.start_time} - {props.post.acf.end_time} <LocationLink href={`https://www.google.com/maps/search/?api=1&query=${props.locationLat},${props.locationLng}`} target="_blank">{props.post.acf.location.address}</LocationLink></EventDetails>
              ) : null
            : null
        }
        <Content html={props.post.acf.post_content} />
        <RelatedLinks 
          heading="Related Links" 
          links={props.post.acf.related_links} />
      </Post> 
      : null 
    }
  </Container>