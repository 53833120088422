import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

import HTMLRenderer from "./HTMLRenderer"

const PostPartial = styled.article`
  border-bottom: 1px solid ${theme.themeColors.themeNeutral};
  flex-basis: 100%;
  padding: ${theme.padding.insetXL} 0;
  width: 100%;

  &:first-of-type {
    padding-top: ${theme.spaces.base};
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const Heading = styled.h1`
  color: ${theme.themeColors.themeTextCommon};
  margin-top: 0;

  .mission & {
    font-size: ${theme.fontSizes.fontSizeL};
  }
`

const SubHeading = styled.p`
  color: ${theme.themeColors.themeTextEmphasized};
  font-size: ${theme.fontSizes.fontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const Excerpt = styled(HTMLRenderer)`
  margin-top: ${theme.spaces.base};
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin: ${theme.margin.stack};
`
const EventDetails = styled.p`
  color: ${theme.themeColors.themeTextEmphasized};
  font-size: ${theme.fontSizes.fontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const LocationLink = styled.a`
  display: block;
  margin: ${theme.margin.stackXXS};
`

export default props => {
  return (
    <PostPartial>
        <Heading dangerouslySetInnerHTML={{__html: props.heading}}></Heading> 
        {
          props.subHeading || props.date ?
            props.postIsEvent ?
              (props.postIsEvent === "No" ?
                <SubHeading dangerouslySetInnerHTML={{__html: props.subHeading}}></SubHeading> :
                <EventDetails>{props.date}, {props.startTime} - {props.endTime}<LocationLink href={`https://www.google.com/maps/search/?api=1&query=${props.locationLat},${props.locationLng}`} target="_blank">{props.locationAddress}</LocationLink></EventDetails> 
              ) : null
            : null
        }
        <Excerpt html={props.excerpt} />
        <StyledLink to={props.linkUrl}>{props.linkText}</StyledLink>
      </PostPartial>
  )
}