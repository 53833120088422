/*eslint-disable no-unused-vars*/
import React, { Component, Fragment } from "react"
/*eslint-enable no-unused-vars*/
import "whatwg-fetch"
import styled, { ThemeProvider } from "styled-components"
import theme from "../Theme"

import formatAddress from "../scripts/formatAddress"

import Container from "../components/Container"
import Details from "../components/pieceDetail/Details"
import Footer from "../components/Footer"
import Gallery from "../components/pieceDetail/Gallery"
import Header from "../components/Header"
import HighlightBanner from "../components/pieceDetail/HighlightBanner"
import InfoSection from "../components/pieceDetail/InfoSection"
import Loader from "../components/Loader"
import Main from "../components/Main"
import Spotlight from "../components/Spotlight"

const VideoContainer = styled.div`
  margin: ${theme.margin.stackL};
`

const VideoHeading = styled.h1`
  font-size: ${theme.fontSizes.fontSize};
`
const Videos = styled.div``

const Video = styled.video`
  margin-top: ${theme.spaces.base};
  width: 100%;
`

class pieceDetail extends Component {

  componentDidMount() {
    const { pieceid } = this.props.match.params
    // this.props.loading = true
    this.props.getPieceDetails(pieceid)

  }

  render() {
    
    return (
      <ThemeProvider theme={{ mode: "main" }}>
          <div className="App">
            {
              this.props.piece.acf && !this.props.loading ?
                <Spotlight
                  image={ this.props.piece._embedded ?
                    this.props.piece._embedded["wp:featuredmedia"]["0"]
                      .media_details.sizes["piece-banner"].source_url
                    : null
                  }
                  overlay={{
                    heading: this.props.piece
                      ? this.props.piece.title.rendered
                      : null,
                      subheading: this.props.piece
                      ? this.props.piece.acf.artist
                      : null
                  }}
                />                  
              : null
            }
            <Header />

            <div className="flex-grow">
              <Loader loading={ this.props.loading }>
                {
                  this.props.piece.acf ?
                    <Fragment>
                      <InfoSection 
                        types={this.props.piece.acf.art_types}
                        year={this.props.piece.acf.year}
                        themes={this.props.piece.acf.art_themes}
                        address={formatAddress(this.props.piece.acf.address.address)}
                        lat={this.props.piece.acf.address.lat}
                        lng={this.props.piece.acf.address.lng}
                        locationName={this.props.piece.acf.location_name}
                        locationNotes={this.props.piece.acf.location_notes}
                        buttonUrl={this.props.match.params.pieceid}
                        update={ this.props.update } />
                  
                      <HighlightBanner 
                        show={this.props.piece.acf.collaboration_details.length > 0}
                        text={this.props.piece.acf.collaboration_details} />
                
                      <Main>
                        <Container>
                          <Details 
                            description={this.props.piece.acf.description}
                            articles={this.props.piece.acf.articles}
                          />
                          {
                            this.props.piece.acf.gallery ?
                              <Gallery
                                piece={this.props.piece}
                                gallery={this.props.piece.acf.gallery}
                                lightboxIsOpen={this.props.lightboxIsOpen}
                              />
                            : null
                          }
                          {
                            this.props.piece.acf.videos ?
                              <VideoContainer>
                                <VideoHeading>Videos</VideoHeading>
                                <Videos>
                                  {
                                    this.props.piece.acf.videos.map(item =>
                                      <Video controls src={item.url} key={item.id}>Sorry, your browser doesn't support embedded videos</Video>
                                    )
                                  }
                                </Videos>
                              </VideoContainer>
                            : null
                          }
                        </Container>
                      </Main>
                    </Fragment>
                  : null
                }
                </Loader>
              </div>
            <Footer />
          </div>
      </ThemeProvider>
    )
  }
}

export default pieceDetail
