import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

import HTMLRenderer from "./HTMLRenderer"

const postVariables = {
  titleColor: `${theme.themeColors.themeTextCommon}`,
  titleSizeMission: `${theme.fontSizes.fontSizeL}`,
  subTitleColor: `${theme.themeColors.themeTextEmphasized}`,
  subTitleFontSize: `${theme.fontSizes.fontSize}`
}

const PostExcerpt = styled.article`
  flex-basis: 100%;

  &:nth-of-type(2) {
    margin: ${theme.margin.stackXL};
  }

  ${theme.media.md`
    margin: ${theme.margin.inlineL};
    flex-basis: ${props => (props.postCount > 1) ? `calc(50% - (2.5888rem / 2))` : `100%`};
    width: ${props => (props.postCount > 1) ? `calc(50% - (2.5888rem / 2))` : `100%`};

    &:last-of-type {
      margin: 0;
    }
  `}
`

const Heading = styled.h1`
  color: ${postVariables.titleColor};
  margin-top: 0;

  .mission & {
    font-size: ${postVariables.titleSizeMission};
  }
`

const SubHeading = styled.p`
  color: ${postVariables.subTitleColor};
  font-size: ${postVariables.subTitleFontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const Excerpt = styled(HTMLRenderer)`
  margin-top: ${theme.spaces.base};
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin: ${theme.margin.stack};
`
const EventDetails = styled.p`
  color: ${postVariables.subTitleColor};
  font-size: ${postVariables.subTitleFontSize};
  font-weight: 500;
  margin: ${theme.margin.stackXXS};
`

const LocationLink = styled.a`
  display: block;
  margin: ${theme.margin.stackXXS};
`

export default props => {
  return (
    <PostExcerpt {...props}>
        <Heading dangerouslySetInnerHTML={{__html: props.heading}}></Heading> 
        {
          props.subHeading || props.date ?
            props.postIsEvent ?
              (props.postIsEvent === "No" ?
                <SubHeading dangerouslySetInnerHTML={{__html: props.subHeading}}></SubHeading> :
                <EventDetails>{props.date}, {props.startTime} - {props.endTime}<LocationLink href={`https://www.google.com/maps/search/?api=1&query=${props.locationLat},${props.locationLng}`} target="_blank">{props.locationAddress}</LocationLink></EventDetails> 
              ) : null
            : null
        }
        <Excerpt html={props.excerpt} />
        <StyledLink to={props.linkUrl}>{props.linkText}</StyledLink>
      </PostExcerpt>
  )
}