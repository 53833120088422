/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

import Container from "./Container"
import PostExcerpt from "./PostExcerpt"

const PostShowcase = styled.section`
  background-color: ${theme.themeColors.themeNeutralTransparent};
  border: 1px solid ${theme.themeColors.themeNeutral};
  border-radius: 2px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.padding.inset};

  ${theme.media.sm`
    margin: ${theme.margin.stackXL};
  `}
`
const Heading = styled.h1`
  flex-basis: 100%;
  width: 100%;
  font-size: ${theme.fontSizes.fontSize};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.6rem;
`

const ShowcaseContainer = styled(Container)`
  /* padding-left: 0;
  padding-right: 0; */
`

export default (props) => {
  if(props.posts.length > 0) {
    return(
      <ShowcaseContainer>
        <PostShowcase>
          <Heading>News and Events</Heading>
          {
            props.posts.map((post) => {
              if(post.acf.post_is_event === "Yes") {
                return (
                  <PostExcerpt
                    key={post.id}
                    content={post.acf.post_content}
                    date={post.acf.date}
                    endTime={post.acf.end_time}
                    excerpt={post.excerpt.rendered}
                    heading={post.title.rendered}
                    linkText="Read More"
                    linkUrl={`/posts/${post.id}`}
                    locationAddress={post.acf.location.address}
                    locationLat={post.acf.location.lat}
                    locationLng={post.acf.location.lng}
                    postCount={props.postCount}
                    postIsEvent={post.acf.post_is_event}
                    subHeading={post.acf.subtitle}
                    startTime={post.acf.start_time}
                  /> 
                )
              } 
              else {
                return (
                  <PostExcerpt
                    key={post.id}
                    content={post.acf.post_content}
                    excerpt={post.excerpt.rendered}
                    heading={post.title.rendered}
                    linkText="Read More"
                    linkUrl={`/posts/${post.id}`}
                    postCount={props.postCount}
                    postIsEvent={post.acf.post_is_event}
                    subHeading={post.acf.subtitle}
                  />
                )
              }
            })
          }
        </PostShowcase>
      </ShowcaseContainer>
    )
  } else {
    return null
  }
}