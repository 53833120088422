/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import "whatwg-fetch"
import { ThemeProvider } from "styled-components"

import Footer from "../components/Footer"
import Header from "../components/Header"
import Loader from "../components/Loader"
import PostList from "../components/PostList"

class Posts extends Component {
  componentDidMount() {
    this.props.getAllPosts()
  }
  
  render() {
    return (
      <ThemeProvider theme={{ mode: "main" }}>
        <div className="App">
          <Header />
          <div className="flex-grow">
            <Loader loading={ this.props.loading }>
            { 
              <PostList posts={this.props.posts} />
            }
            </Loader>
          </div>
        <Footer />
      </div>
    </ThemeProvider>
    )
  }
}
export default Posts