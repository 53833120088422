/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import "whatwg-fetch"

// themeing and images
import styled, { ThemeProvider } from "styled-components"
import theme from "../Theme"

// helper functions
import buildCardGridArray from "../scripts/buildCardGridArrayOld"

// components
import Button from "../components/MapButton"
import Container from "../components/Container"
import Footer from "../components/Footer"
import CardGrid from "../components/CardGrid"
import Header from "../components/Header"
import Loader from "../components/Loader"
import PostExcerpt from "../components/PostExcerpt"
import PostShowcase from "../components/PostShowcase"
import Spotlight from "../components/Spotlight"

const Homebody = styled.div`
  position: relative;

  &:before {
    background-image: url("../map.png");
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    display: ${props => props.hide ? "none" : "block"};
    height: 851px;
    width: 559px;
    position: absolute;
    right: 0;
    top: 108vw;

    ${theme.media.sm`
      top: 62vw;
    `} ${theme.media.lg`
      top:57vw;
    `};
  }
`

const Explore = styled.section`
  background-color: ${theme.themeColors.themePrimary};
  color: ${theme.themeColors.themeTextCommonInverse};
  padding: ${theme.spaces.xxl} 0 ${theme.spaces.xxxxl};
`

const ExploreContainer = styled.div`
  ${theme.media.sm`
    width: 75%;
  `}
`

const Heading = styled.h1`
  font-size: ${theme.fontSizes.fontSize};
  font-weight: 400;
  margin-top: 0;
`

const Description = styled.p`
  font-size: ${theme.fontSizes.fontSize3XL};
  margin: ${theme.margin.stackS};
`

const Actions = styled.ul`
  list-style-type: none;
  margin: ${theme.margin.stackL};
  padding: 0;
`

const ActionItem = styled.li`
  display: inline-block;
  margin: ${theme.margin.inline};
`

const FeatureContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`

const FeaturedPieces = styled.div`
  background-color: ${theme.themeColors.themeBase};
  display: flex;
  flex-wrap: wrap;
  margin-top: -10.9656rem;
  padding: ${theme.padding.inset};
  /* box-shadow: 0 0 10px ${theme.themeColors.themeAlmostBlackTransparent}; */
  /* border: 1px solid ${theme.themeColors.themeNeutral}; */
`

const Mission = styled.section`
  background-color: ${theme.themeColors.themePrimary};
  color: ${theme.themeColors.themeTextCommonInverse};
  padding: ${theme.spaces.xxxl} 0 ${theme.spaces.xxl};
  margin: ${theme.margin.stackXXL};
  -webkit-clip-path: polygon(0 0%, 100% 15%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0% 100%);
`

const MissionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  h1 {
    color: #fff;
  }

  a {
    align-self: center;
    color: ${theme.themeColors.themeTextCommonInverse};
    font-weight: 500;
    margin-top: ${theme.spaces.s};
    text-decoration: none;
    
    ::after {
      content: "→";
      color: ${theme.themeColors.themeTextAction};
      font-size: ${theme.fontSizes.fontSizeXL};
      padding: ${theme.padding.insetS};
      vertical-align: middle;
    }
  }
`

const MapButton = styled(Button)`
  background-image: url("/map-icon-white.png");
  padding-left: ${theme.spaces.xl};
`

const ListButton = styled(Button)`
  background-color: ${theme.themeColors.themeActionSecondary};
  background-image: url("/list-icon-inverse.png");
  color: ${theme.themeColors.themeTextCommon};
  padding-left: ${theme.spaces.xl};
  
  &:hover {
    background-color: ${theme.themeColors.themeActionSecondary2};
  }
  
  &:active {
    background-color: ${theme.themeColors.themeActionSecondary3};
  }
`

class Home extends Component {

  componentDidMount() {
    this.props.getHomePageData()
  }

  render() {
    return (
      <ThemeProvider theme={{ mode: "main" }}>
        <Homebody hide={this.props.loading}>
          <div className="App">

            {/* {
              this.props.homePageData && !this.props.loading ?
                <Spotlight
                  image={this.props.homePageData.spotlightData ? this.props.homePageData.spotlightData.image : null}
                  overlay={{
                    heading: this.props.homePageData.spotlightData ? this.props.homePageData.spotlightData.title : null,
                    subheading: this.props.homePageData.spotlightData ? this.props.homePageData.spotlightData.artist : null,
                    linkText: "View Piece",
                    linkUrl: this.props.homePageData.spotlightData ? `/piece/${this.props.homePageData.spotlightData.id}` : '#'
                  }}
                />
              : null
            } */}
            {
              this.props.spotlightPiece.acf && !this.props.loading ?
                <Spotlight
                  image={
                    this.props.spotlightPiece._embedded
                      ? this.props.spotlightPiece._embedded["wp:featuredmedia"]["0"]
                        .media_details.sizes["piece-banner"].source_url
                      : null
                  }
                  overlay={{
                    heading: this.props.spotlightPiece
                      ? this.props.spotlightPiece.title.rendered
                      : null,
                    subheading: this.props.spotlightPiece
                      ? this.props.spotlightPiece.acf.artist
                      : null,
                    linkText: "View Piece",
                    linkUrl: this.props.spotlightPiece
                      ? `/piece/${this.props.spotlightPiece.id}`
                      : null
                  }}
                />
                : null
            }

            <Header />
            <div className="flex-grow">
              <Loader loading={this.props.loading}>
                <Explore className="explore">
                  <Container className="container">
                    <ExploreContainer className="explore-container">
                      <Heading>Explore</Heading>
                      <Description>
                        Use our interactive map or list to browse Columbia’s
                        regional public art
                      </Description>
                      <Actions>
                        <ActionItem>
                          <MapButton to={`/pieces/map`}>Map</MapButton>
                        </ActionItem>
                        <ActionItem>
                          <ListButton to={`/pieces/list`}>List</ListButton>
                        </ActionItem>
                      </Actions>
                    </ExploreContainer>
                  </Container>
                </Explore>

                <FeatureContainer>
                  <FeaturedPieces>
                    {this.props.featuredPieces.length === 0 ? (
                      <div>Featured Pieces Not Found</div>
                    ) : (
                      <CardGrid
                        cards={buildCardGridArray(this.props.featuredPieces)}
                        columns={{ sm: 3, md: 3, lg: 3, xl: 3 }}
                      >
                        No pieces
                      </CardGrid>
                    )}
                  </FeaturedPieces>
                </FeatureContainer>

                <PostShowcase
                  posts={this.props.featuredPosts}
                  postCount={this.props.featuredPosts.length}
                />

                <Mission className="mission">
                  <Container>
                    <MissionContainer>
                      <PostExcerpt
                        heading={
                          this.props.homePageData.acf
                            ? this.props.homePageData.acf.mission.post_title
                            : null
                        }
                        excerpt={
                          this.props.homePageData.acf
                            ? this.props.homePageData.acf.mission.post_excerpt
                            : null
                        }
                        linkUrl={
                          this.props.homePageData.acf
                            ? `/posts/${this.props.homePageData.acf.mission.ID}`
                            : null
                        }
                        linkText="Read More"
                        postCount={2}
                      />
                      <PostExcerpt
                        heading={
                          this.props.homePageData.acf
                            ? this.props.homePageData.acf.call_to_artists.post_title
                            : null
                        }
                        excerpt={
                          this.props.homePageData.acf
                            ? this.props.homePageData.acf.call_to_artists.post_excerpt
                            : null
                        }
                        linkUrl={
                          this.props.homePageData.acf
                            ? `/posts/${this.props.homePageData.acf.call_to_artists.ID
                            }`
                            : null
                        }
                        linkText="Read More"
                        postCount={2}
                      />
                    </MissionContainer>
                  </Container>
                </Mission>
              </Loader>
            </div>
            <Footer />
          </div>
        </Homebody>
      </ThemeProvider>
    )
  }
}

export default Home
