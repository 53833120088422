/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled, { keyframes } from "styled-components"
import theme from "../Theme"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.p`
  animation: ${ rotate } 0.7s linear infinite;
  border: 1px solid ${ theme.themeColors.themePrimary };
  border-top-color: ${ theme.themeColors.themeBase };
  border-radius: 50%;
  height: 3rem;
  margin: auto;
  position: relative;
  width: 3rem;
`


const Wrapper = styled.div`
  background-color: ${ theme.themeColors.themeTransparentDark };
  display: flex;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export default props =>
  <div className={ props.className }>
    {
      props.loading ?
        <Wrapper>
          <Loader />
        </Wrapper> :
        props.children
    }
  </div>
