/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import "whatwg-fetch"
import { ThemeProvider } from "styled-components"

import Footer from "../components/Footer"
import Header from "../components/Header"
import ImageBanner from "../components/ImageBanner"
import Loader from "../components/Loader"
import Post from "../components/Post"

class PostPage extends Component {

  componentDidMount() {
    const { postid } = this.props.match.params
    this.props.getPostData(postid)
  }

  render() {
    return (
      <ThemeProvider theme={{ mode: "main" }}>
        <div className="App">
          { 
            this.props.post._embedded && this.props.post._embedded["wp:featuredmedia"] && !this.props.loading ?
                <ImageBanner 
                  image={this.props.post._embedded["wp:featuredmedia"]["0"]
                          .media_details.sizes["post-banner"].source_url} />
            : null
          }
          <Header />
          <div className="flex-grow">
            <Loader loading={ this.props.loading }>
              <Post post={ this.props.post } />
            </Loader>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    )
  }
}
export default PostPage