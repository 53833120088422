/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

export default styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme.contentMaxWidth.elevenforty};
  padding-left: ${theme.spaces.base};
  padding-right: ${theme.spaces.base};
  position: relative;
  width: 100%;
`
