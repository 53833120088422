/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

import Overlay from "./Overlay"

const Spotlight = styled.section`
  background-color: ${props => props.image ? "null" : theme.themeColors.themeTransparentDark };
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vw;
  z-index: 1; // to sit on top of the loading overlay

  ${theme.media.sm`
    height: 50vw;
  `}
`

export default props => 
  <Spotlight image={props.image}>
    <Overlay 
      heading={props.overlay.heading} 
      subheading={props.overlay.subheading} 
      linkText={props.overlay.linkText}
      linkUrl={props.overlay.linkUrl} />
  </Spotlight>
