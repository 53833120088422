/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import { css } from "styled-components"

export const breakpoints = {
  xs: 0,
  xssm: 475,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const extraBreaks = {
  xs: 500
}

export const mq = {
  above: {
    sm: (...args) => css`
      @media (min-width: ${breakpoints.sm / 16}em) {
        ${css(...args)}
      }
    `,
    md: (...args) => css`
      @media (min-width: ${breakpoints.md / 16}em) {
        ${css(...args)}
      }
    `,
    lg: (...args) => css`
      @media (min-width: ${breakpoints.lg / 16}em) {
        ${css(...args)}
      }
    `,
    xl: (...args) => css`
      @media (min-width: ${breakpoints.xl / 16}em) {
        ${css(...args)}
      }
    `,
  },
  below: {
    xs: (...args) => css`
      @media (max-width: ${(breakpoints.xs - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    sm: (...args) => css`
      @media (max-width: ${(breakpoints.sm - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    md: (...args) => css`
      @media (max-width: ${(breakpoints.md - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    lg: (...args) => css`
      @media (max-width: ${(breakpoints.lg - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    xl: (...args) => css`
      @media (max-width: ${(breakpoints.xl - 1) / 16}em) {
        ${css(...args)}
      }
    `,
  },
  only: {
    xs: (...args) => css`
      @media (min-width: ${breakpoints.xs / 16}em) and (max-width: ${(breakpoints.sm - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    sm: (...args) => css`
      @media (min-width: ${breakpoints.sm / 16}em) and (max-width: ${(breakpoints.md - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    md: (...args) => css`
      @media (min-width: ${breakpoints.md / 16}em) and (max-width: ${(breakpoints.lg - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    lg: (...args) => css`
      @media (min-width: ${breakpoints.lg / 16}em) and (max-width: ${(breakpoints.xl - 1) / 16}em) {
        ${css(...args)}
      }
    `,
    xl: (...args) => css`
      @media (min-width: ${breakpoints.xl / 16}em) {
        ${css(...args)}
      }
    `
  }
}

// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16
  accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `
  return accumulator
}, {})

export const mediaBetween = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16
  accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `
  return accumulator
}, {})

// Spacing, Margin and Padding
export const spaces = {
  xxs: "0.3776rem",
  xs: "0.6110rem",
  s: "0.9888rem",
  base: "1.6rem",
  l: "2.5888rem",
  xl: "4.1886rem",
  xxl: "6.7772rem",
  xxxl: "10.9656rem",
  xxxxl: "17.7428rem"
}

export const margin = {
  stackXXS: "0.3776rem 0 0 0",
  stackXS: "0.6110rem 0 0 0",
  stackS: "0.9888rem 0 0 0",
  stack: "1.6rem 0 0 0",
  stackL: "2.5888rem 0 0 0",
  stackXL: "4.1886rem 0 0 0",
  stackXXL: "6.7772rem 0 0 0",
  inlineXXS: "0 0.3776rem 0 0",
  inlineXS: "0 0.6110rem 0 0",
  inlineS: "0 0.9888rem 0 0",
  inline: "0 1.6rem 0 0",
  inlineL: "0 2.5888rem 0 0",
  inlineXL: "0 4.1886rem 0 0",
  inlineXXL: "0 6.7772rem 0 0",
}

export const padding = {
  insetXXS: "0.3776rem",
  insetXS: "0.6110rem",
  insetS: "0.9888rem",
  inset: "1.6rem",
  insetL: "2.5888rem",
  insetXL: "4.1886rem",
  insetXXL: "6.7772rem",
  insetSquishXXS: "0.3776rem 0.6110rem",
  insetSquishXS: "0.3776rem 0.9888rem",
  insetSquishS: "0.6110rem 1.6rem",
  insetSquish: "0.9888rem 2.5888rem",
  insetSquishL: "1.6rem 4.1886rem",
  insetSquishXL: "2.5888rem 6.7772rem",
  insetSquishXXL: "6.7772rem 8.0rem",
}

// Colors
const colors = {
  cyprus: "#0a3C4e",
  blueStone: "#0e4f67",
  columbiaBlue: "#b5ebfe",
  columbiaBlueDark: "#9ce4fe",
  columbiaBlueDarkest: "#8de0fd",
  aliceBlue: "#f2f8ff",
  sunset: "#f45848",
  sunsetDark: "#f34230",
  sunsetDarkest: "#f23522",
  grenadier: "#c74638",
  blueWhale: "#12272e",
  white: "#fff",
  black: "#000",
  almostBlack: "#0C3644",
  almostBlackTransparent: "rgba(12,54,68,0.5)",
  blackTransparent: "rgba(0,0,0,0.65)",
  grey: "rgba(224, 232, 241, 1)",
  greyTransparent: "rgba(224, 232, 241, 0.5)",
  whiteTransparent: "rgba(255, 255, 255, 0.5)"
}

export const themeColors = {
  themeBase: `${colors.aliceBlue}`,
  themeBase2: `${colors.columbiaBlue}`,
  themeBase3: `${colors.white}`,
  themeBase4: `${colors.almostBlack}`,
  themePrimary: `${colors.cyprus}`,
  themePrimary2: `${colors.blueStone}`,
  themeAction: `${colors.sunset}`,
  themeAction2: `${colors.sunsetDark}`,
  themeAction3: `${colors.sunsetDarkest}`,
  themeActionSecondary: `${colors.columbiaBlue}`,
  themeActionSecondary2: `${colors.columbiaBlueDark}`,
  themeActionSecondary3: `${colors.columbiaBlueDarkest}`,
  themeTextEmphasized: `${colors.blueStone}`,
  themeTextEmphasizedInverse: `${colors.white}`,
  themeTextCommon: `${colors.almostBlack}`,
  themeTextCommonInverse: `${colors.aliceBlue}`,
  themeTextAction: `${colors.grenadier}`,
  themeTransparentDark: `${colors.blackTransparent}`,
  themeNeutral: `${colors.grey}`,
  themeNeutralTransparent: `${colors.whiteTransparent}`,
  themeAlmostBlackTransparent: `${colors.almostBlackTransparent}`,
}

// Fonts
export const fontSizes = {
  fontSizeXS: "1.111rem",
  fontSizeS: "1.333rem",
  fontSize: "1.6rem",
  fontSizeL: "1.92rem",
  fontSizeXL: "2.304rem",
  fontSize2XL: "2.7648rem",
  fontSize3XL: "3.1048rem"
}

export const contentMaxWidth = {
  elevenforty: "1140px"
}

export default { breakpoints, contentMaxWidth, fontSizes, margin, mq, media, padding, spaces, themeColors }