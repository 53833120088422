/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
// themeing and images
import styled from "styled-components"
import theme from "../Theme"

export default styled.main`
    padding: ${theme.spaces.l} 0 ${theme.spaces.xxl} 0;
`
