/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

import Button from "../components/Button"


const Wrapper = styled.div`
  display: ${props => props.expanded ? "block" : "none"};
  width: 100%;

  ${theme.media.md`
    display: block;
  `}
`

const InputWrapper = styled.div`
  margin: ${ theme.margin.stackS };
`

const Label = styled.label`
  display: block;
  font-size: ${theme.fontSizes.fontSizeS};
`

const SearchInput = styled.input`
  background-color: ${ theme.themeColors.themeBase };
  display: block;
  width: 100%;
`

const LocationInput = styled.select`
  background-color: ${ theme.themeColors.themeBase };
  display: block;
  width: 100%;
`
  
const ThemeInput = styled.select`
  background-color: ${ theme.themeColors.themeBase };
  display: block;
  width: 100%;
`
  
const TypeInput = styled.select`
  background-color: ${ theme.themeColors.themeBase };
  display: block;
  width: 100%;
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin: ${theme.margin.stack};
  justify-content: space-between;
`

const Reset = styled.a`
  color: ${theme.themeColors.themeTextCommonInverse};
  cursor: pointer;
  margin-right: ${theme.spaces.xl};
  text-decoration: underline;
`

class Filters extends Component {
  constructor(props) {
    super(props)

    // this is not working. The props are there when the component load but the state is an empty string
    // if you go away from the map and come back the state value is now the last value you had selected.
    this.state = {
      "search": props.search,
      "location": props.selectedLocation,
      "theme": props.selectedTheme,
      "type": props.selectedType
    }

    this.setSearch = this.setSearch.bind(this)
    this.setLocation = this.setLocation.bind(this)
    this.setTheme = this.setTheme.bind(this)
    this.setType = this.setType.bind(this)
    this.reset = this.reset.bind(this)
    this.elevateState = this.elevateState.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocation !== prevProps.selectedLocation) {
      this.setState({"location": this.props.selectedLocation});
    }
    if (this.props.selectedTheme !== prevProps.selectedTheme) {
      this.setState({"theme": this.props.selectedTheme});
    }
    if (this.props.selectedType !== prevProps.selectedType) {
      this.setState({"type": this.props.selectedType});
    }
  }

  elevateState(state) {
    this.props.update({ ...state })
  }

  reset() {
    const clearedState = {
      "search": "",
      "location": "",
      "theme": "",
      "type": ""
    }
    this.setState(clearedState)

    this.elevateState(clearedState)
  }

  setSearch(event) {
    this.setState({ "search": event.target.value })
  }

  setLocation(event) {
    this.setState({ "location": event.target.value })
  }

  setTheme(event) {
    this.setState({ "theme": event.target.value })
  }

  setType(event) {
    this.setState({ "type": event.target.value })
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.elevateState(this.state)
      event.preventDefault()
    }
  }


  render() {
    return (
      <Wrapper { ...this.props } onKeyPress={ this.handleKeyPress }>
        <InputWrapper>
          <Label>Search</Label>
          <SearchInput
            value={ this.state.search }
            onChange={ this.setSearch }>
          </SearchInput>
        </InputWrapper>
        {
          this.props.locations ?
            <InputWrapper>
              <Label>City</Label>
              <LocationInput
                value={ this.state.location }
                onChange={ this.setLocation }>
                {
                  this.props.locations.map((location, i) => 
                  <option key={ i }>{ location }</option>
                )
              }
              </LocationInput>
            </InputWrapper> :
            null
          }
        {
          this.props.themes ?
            <InputWrapper>
              <Label>Theme</Label>
              <ThemeInput
                value={ this.state.theme }
                onChange={ this.setTheme }>
                {
                  this.props.themes.map((theme, i) => 
                  <option key={ i }>{ theme }</option>
                )
              }
              </ThemeInput>
            </InputWrapper> :
            null
          }
        {
          this.props.types ?
            <InputWrapper>
              <Label>Type</Label>
              <TypeInput
                value={ this.state.type }
                onChange={ this.setType }>
                {
                  this.props.types.map((type, i) => 
                    <option key={ i }>{ type }</option>
                  )
                }
              </TypeInput>
            </InputWrapper> :
            null
        }
        <Actions>
          <Reset 
            onClick= { this.reset } role="button" tabIndex="0">Reset</Reset>
          <Button text="Update"
            onClick= { () => this.elevateState(this.state) } role="button" tabIndex="0" />
        </Actions>
    
      </Wrapper>
    )
  }
}

export default Filters
