import React, { Component } from "react"
import { Link } from "react-router-dom"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import theme from "../Theme"

import InfoPanel from "../components/InfoPanel"

import formatAddress from "../scripts/formatAddress"

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Point = styled(Link)`
  background-color: ${props => props.selected ? `#fff` : `#000`};
  background-color: ${theme.themeColors.themeAction};
  border: 3px solid ${theme.themeColors.themeAction};
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: block;
  height: 20px;
  transform: translateX(-50%);
  transition: height 0.3s, width 0.3s, box-shadow 0.3s;
  width: 20px;
  
  &:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    height: 25px;
    width: 25px;
  }
`

const Piece = styled.div`

`

const StyledInfoPanel = styled(InfoPanel)`
  bottom: ${theme.spaces.base};
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
`

class Map extends Component {
  constructor(props) {
    super(props)

    this.createMapOptions = this.createMapOptions.bind(this)
  }

  static defaultProps = {
    center: {
      lat: 34.0007104,
      lng: -81.03481440000002
    },
    zoom: 15
  };

  get selectedPiece() {
    return this.props.pieces.find(piece => piece.id.toString() === this.props.selected)
  }

  createMapOptions(maps) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
        style: maps.ZoomControlStyle.SMALL,
      },
      gestureHandling: 'cooperative',
      fullscreenControl: false
    };
  }

  render() {
    const { selected, center, zoom, pieces } = this.props

    return (
      // Important! Always set the container height explicitly
      // local dev - bootstrapURLKeys={{ key: "AIzaSyDDOk2fZhaUiXF3HDiG-N6dfw_FNtpJrNU" }}
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBCky38MfZzZn7yXecfXJ6J09CJffm7P1A" }}
          defaultCenter={center}
          defaultZoom={zoom}
          options={this.createMapOptions} >
          {
            pieces.map((piece, i) =>
              <Piece
                key={i}
                lat={piece.address.lat}
                lng={piece.address.lng}>
                {
                  selected !== undefined && selected === piece.id.toString() ?
                    <StyledInfoPanel
                      heading={this.selectedPiece.title}
                      address={formatAddress(this.selectedPiece.address.address)}
                      linkText="View Piece"
                      linkUrl={`/piece/${selected}`} /> :
                    null
                }
                <Point
                  to={`/pieces/map/${piece.id}`}
                  selected={piece.id.toString() === selected} />
              </Piece>
            )
          }
        </GoogleMapReact>
      </Wrapper>
    );
  }
}

export default Map;
