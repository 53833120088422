/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme, { mq } from "../Theme"

import Card from "./Card"

const cols = items =>
  `
    flex-basis: calc(
      ${100 / items}% - ${theme.spaces.base} + (${theme.spaces.base } / ${items})
    );

    &:nth-child(${items}n) {
      margin-right: 0;
    }

    &:nth-child(-n + ${items}) {
      margin-top: 0;
    }
  `

const CardGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`

const StyledCard = styled(Card)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${theme.spaces.base};

  &:last-child {
      margin-right: 0;
  }

  ${mq.only.xs`
    ${ props => props.columns && props.columns.xs ? cols(props.columns.xs) : cols(1) }
  `}

  ${mq.only.sm`
    ${ props => props.columns && props.columns.sm ? cols(props.columns.sm) : cols(2) }
    `}
    
  ${mq.only.md`
    ${ props => props.columns && props.columns.md ? cols(props.columns.md) : cols(3) }
  `}
    
  ${mq.only.lg`
    ${ props => props.columns && props.columns.lg ? cols(props.columns.lg) : cols(4) }
  `}

  ${mq.only.xl`
    ${ props => props.columns && props.columns.xl ? cols(props.columns.xl) : cols(5) }
  `}
`

export default props => 
  <CardGrid {...props}>
    {
      props.cards.map((card, i) => 
        <StyledCard
          columns={props.columns}
          key={card.id || i}
          image={card.image}
          url={card.url}
          heading={card.heading}
          address={card.address}
          distance={card.distance} />
      )
    }
  </CardGrid>
