/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../../Theme"

import HTMLRenderer from "../../components/HTMLRenderer"
import LinkList from "../../components/LinkList"

const Details = styled.section`
  display: flex;
  flex-wrap: wrap;
`

const Description = styled.div`
  flex-basis: 100%;
  margin: 0;
  width: 100%;
  
  ${theme.media.md`
    flex-basis: calc(70% - ${theme.spaces.base});
    flex-grow: 1;
    margin: ${theme.margin.inline};
    width: calc(70% - ${theme.spaces.base});
  `}
`

const DescriptionContent = styled(HTMLRenderer)``

const DescriptionHeading = styled.h1`
  margin: 0;
`

const RelatedLinksContainer = styled.div`
  flex-basis: 100%;
  width: 100%;
  
  ${theme.media.md`
    flex-basis: 30%;
    flex-grow: 1;
    width: 30%;
  `}
`

const RelatedLinks = styled(LinkList)``

export default props => {
  return (
    <Details>
      {
        props.description ?
          <Description>
            <DescriptionHeading>Description</DescriptionHeading>
            <DescriptionContent html={props.description} />
          </Description>
        : null
      }
      {
        props.articles.length > 0 ?
          <RelatedLinksContainer>
            <RelatedLinks
              heading="Related Links"
              links={props.articles}
            />
          </RelatedLinksContainer>
          : null
        }
    </Details>
  )
}
