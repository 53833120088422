/*eslint-disable no-unused-vars*/
import React, { Component } from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../../Theme"

import Container from "../../components/Container"

const HighlightBanner = styled.section`
  background-color: ${theme.themeColors.themeBase2};
  display: ${props => props.show ? "block" : "none"};
  padding: ${theme.padding.insetL};
  text-align: center;
`
const HighlightBannerText = styled.p`
  margin: 0;
`
export default props => {
    return (
        <HighlightBanner show={props.show}>
            <Container>
                <HighlightBannerText>{props.text}</HighlightBannerText>
            </Container>
        </HighlightBanner>
    )
}