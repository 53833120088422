import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"


const MainNavigation = styled.nav`
  display: flex;
`

const NavLink = styled(Link)`
  align-items: center;
  background-repeat: no-repeat;
  background-position: left ${theme.spaces.s} center;
  background-size: auto ${theme.spaces.base};
  color: ${theme.themeColors.themeTextEmphasizedInverse};
  display: flex;
  font-size: ${theme.fontSizes.fontSize};
  padding-top: ${theme.spaces.s};
  padding-right: ${theme.spaces.base};
  padding-bottom: ${theme.spaces.s};
  padding-left: ${theme.spaces.l};
  text-decoration: none;

  &:hover {
    background-color: ${theme.themeColors.themePrimary};
  }
`

const MapLink = NavLink.extend`
  background-image: url("/map-icon-white.png");
`

const ListLink = NavLink.extend`
  background-image: url("/list-icon-white.png");
`

export default () => {
  return (
    <MainNavigation>
      <MapLink to={`/pieces/map`}><span>Map</span></MapLink>
      <ListLink to={`/pieces/list`}><span>List</span></ListLink>
    </MainNavigation>
  )
}