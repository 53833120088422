/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import DOMPurify from "dompurify"

export default props =>
  <div
    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.html)}}
    className={props.className}>
  </div>
