/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

const LinkList = styled.div`
  margin: ${theme.margin.stackXL};
  
  ${theme.media.md`
    margin: 0;
  `}
`

const Heading = styled.h1`
  font-size: ${theme.fontSizes.fontSize};
  margin: 0;
`

const Subheading = styled.p`
  margin: 0;
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  margin: ${theme.margin.stack};
`
const Link = styled.a``

export default props => 
  {
    return(
      props.links.length > 0 ?
        <LinkList>
          <Heading>{props.heading}</Heading>
          <Subheading>{props.subheading}</Subheading>
          <List>
            {
              props.links.map((link, i) => 
                <Item key={i}>
                  <Link href={link.url}>{link.title}</Link>
                </Item>
              )
            } 
          </List> 
        </LinkList> 
      : null
    )
  }
