/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

import Container from "./Container"
import PostPartial from "./PostPartial"

const PostList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: ${theme.margin.stackL};
`

const Heading = styled.h1`
  flex-basis: 100%;
  width: 100%;
  font-size: ${theme.fontSizes.fontSize};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.6rem;
`
// const Post = styled.article`
// `

export default props => {
  if(props.posts.length > 0) {
  return(
    <Container>
      <PostList>
        <Heading>News and Events</Heading>
        {
          props.posts.map((post) => {
            if(post.acf.post_is_event === "Yes") {
              return (
                <PostPartial
                  key={post.id}
                  postIsEvent={post.acf.post_is_event}
                  heading={post.title.rendered}
                  subHeading={post.acf.subtitle}
                  date={post.acf.date}
                  startTime={post.acf.start_time}
                  endTime={post.acf.end_time}
                  locationAddress={post.acf.location.address}
                  locationLat={post.acf.location.lat}
                  locationLng={post.acf.location.lng}
                  excerpt={post.excerpt.rendered}
                  content={post.acf.post_content}
                  linkUrl={`/posts/${post.id}`}
                  linkText="Read More" /> 
              )
            } else {
              return (
                <PostPartial
                  key={post.id}
                  postIsEvent={post.acf.post_is_event}
                  heading={post.title.rendered}
                  subHeading={post.acf.subtitle}
                  excerpt={post.excerpt.rendered}
                  content={post.acf.post_content}
                  linkUrl={`/posts/${post.id}`}
                  linkText="Read More" />
              )
            }
          })
        }
      </PostList>
    </Container>
  )} 
  else {
    return null
  }
}
  