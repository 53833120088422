import React from "react"
import styled from "styled-components"

const Address = styled.address``

const LinkedAddress = styled.a`
  color: white;
`

const LocationTitle = styled.p``

const Street = styled.p`
  :nth-child(2) {
    margin: 0;
  }
`
const CityStateZip = styled.p`
  margin-top: 0;
`

export default props => 
  <Address className={props.className}>
    {
      props.isLink && (props.lat && props.lng) ?
        <LinkedAddress href={`https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}`} target="_blank">
          {
            props.address.locationTitle ? 
              <LocationTitle>{props.address.locationTitle}</LocationTitle> :
              null
          }
          {
            props.address.street ? 
              <Street>{props.address.street}</Street> :
              null
          }
          {
            props.address.city && props.address.stateZip ?
              <CityStateZip>{props.address.city}, {props.address.stateZip}</CityStateZip> :
              null
          }
        </LinkedAddress>
        :
        <div>
          {
            props.address.locationTitle ? 
              <LocationTitle>{props.address.locationTitle}</LocationTitle> :
              null
          }
          {
            props.address.street ? 
              <Street>{props.address.street}</Street> :
              null
          }
          {
            props.address.city && props.address.stateZip ?
              <CityStateZip>{props.address.city}, {props.address.stateZip}</CityStateZip> :
              null
          }
        </div>
    }
  </Address>