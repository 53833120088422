/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"

const NoPieces = styled.div`
  background-color: ${theme.themeColors.themeTransparentDark};
  padding: ${theme.spaces.l};
  text-align: center;

  p {
    color: white;
    margin: 0;
    font-weight: 600;
  }
`

export default () =>
  <NoPieces>
    <p>No Pieces to Display</p>
  </NoPieces>