/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import styledTheme from "styled-theming"
import PropTypes from "prop-types"
import theme from "../Theme"

const buttonVariables = {
  backgroundColor: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeAction}` },
    secondary: { main: `${theme.themeColors.themeActionSecondary}` }
  }),
  backgroundColorHover: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeAction2}` },
    secondary: { main: `${theme.themeColors.themeActionSecondary2}` }
  }),
  backgroundColorFocus: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeAction2}` },
    secondary: { main: `${theme.themeColors.themeActionSecondary2}` }
  }),
  backgroundColorActive: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeAction3}` },
    secondary: { main: `${theme.themeColors.themeActionSecondary3}` }
  }),
  backgroundColorVisited: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeAction}` },
    secondary: { main: `${theme.themeColors.themeActionSecondary}` }
  }),
  textColor: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeTextCommonInverse}` },
    secondary: { main: `${theme.themeColors.themeTextCommon}` }
  }),
  textColorHover: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeTextCommonInverse}` },
    secondary: { main: `${theme.themeColors.themeTextCommon}` }
  }),
  textColorFocus: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeTextCommonInverse}` },
    secondary: { main: `${theme.themeColors.themeTextCommon}` }
  }),
  textColorActive: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeTextCommonInverse}` },
    secondary: { main: `${theme.themeColors.themeTextCommon}` }
  }),
  textColorVisited: styledTheme.variants("mode", "variant", {
    primary: { main: `${theme.themeColors.themeTextCommonInverse}` },
    secondary: { main: `${theme.themeColors.themeTextCommon}` }
  }),
  fontSize: `${theme.fontSizes.fontSize}`,
  padding: `${theme.padding.insetSquish}`,
  margin: `${theme.margin.inline}`
}

const Button = styled.a`
    background-color: ${buttonVariables.backgroundColor};
    border: none;
    border-radius: 2px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: ${buttonVariables.textColor};
    cursor: pointer;
    display: ${props => props.inline ? "inline-block" : "block"};
    font-size: ${buttonVariables.fontSize};
    font-weight: 600;
    padding: ${buttonVariables.padding};
    text-decoration: none;
    
    &:visited {
      background-color: ${buttonVariables.backgroundColorVisited};
      color: ${buttonVariables.textColorVisited};
    }

    &:hover {
      background-color: ${buttonVariables.backgroundColorHover};
      color: ${buttonVariables.textColorHover};
    }

    &:active {
      background-color: ${buttonVariables.backgroundColorActive};
      color: ${buttonVariables.textColorActive};
    }

    &:focus {
      background-color: ${buttonVariables.backgroundColorFocus};
      color: ${buttonVariables.textColorFocus};
    }
`

const Icon = styled.img`
    margin-right: ${theme.spaces.xxs};
`

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"])
}

Button.defaultProps = {
  variant: "primary",
}

export default props => 
  <Button {...props} href={props.url}>
    { 
      props.icon ?
        <Icon src={props.icon}/>
      : null
    }
    {props.text}
  </Button>