import React, { Component, Fragment } from "react"
import { Route } from "react-router-dom"
import "whatwg-fetch"
import styled, { ThemeProvider } from "styled-components"
import theme from "../Theme"

import Button from "../components/Button"
import CardGrid from "../components/CardGrid"
import Filters from "../components/Filters"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Loader from "../components/Loader"
import Map from "../components/Map"
import NoPieces from "../components/NoPieces"

import buildCardGridArray from "../scripts/buildCardGridArray"
import Fuse from "fuse.js";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${theme.media.md`
    flex-direction: row;
  `}
`

const Left = styled.div`
  background-color: ${theme.themeColors.themePrimary};
  color: ${theme.themeColors.themeTextCommonInverse};
  display: flex;
  flex-wrap: wrap;
  padding: ${theme.padding.inset};
  z-index: 1;
`

const Toggle = styled(Button)`
  box-shadow: none;
  flex-basis: 100%;
  padding: ${theme.padding.insetXXS};
  position: relative;
  text-align: center;
  width: 100%;

  ::after {
    border-bottom: 2px solid ${theme.themeColors.themeTextCommon};
    border-right: 2px solid ${theme.themeColors.themeTextCommon};
    content: "";
    display: inline-block;
    height: ${theme.spaces.s};
    margin-bottom: ${props => props.expanded ? "0" : `${theme.spaces.xs}`};
    margin-left: ${theme.spaces.s};
    transform: ${props => props.expanded ? "rotate(-135deg)" : "rotate(45deg)"};
    vertical-align: middle;
    width: ${theme.spaces.s};
  }

  ${theme.media.md`
    display: none;
  `}
`

const Right = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  height: ${props => props.map ? "177vw" : "initial"};  
  position: relative;
  
  ${theme.media.xssm`
    height: ${props => props.map ? "149vw" : "initial"};
  `}

  ${theme.media.sm`
    height: ${props => props.map ? "129vw" : "initial"};
  `}

  ${theme.media.md`
    height: initial;
  `}
`

const StyledHeader = styled(Header)`
  padding-left: ${theme.spaces.base};
  padding-right: ${theme.spaces.base};
`

const StyledFooter = styled(Footer)`
  padding-left: ${theme.spaces.base};
  padding-right: ${theme.spaces.base};
`

const StyledCardGrid = styled(CardGrid)`
  padding: ${theme.padding.inset};
`

const StyledFilters = styled(Filters)`
`

const StyledLoader = styled(Loader)`
  height: 100%;
`

class Pieces extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersExpanded: false
    }
    this.toggleFilters = this.toggleFilters.bind(this)
  }

  componentDidMount() {
    this.props.getData(this.props.search)

    const { location, filters, update } = this.props

    if (location.state) {
      filters.search = location.state.search || ``
      filters.location = location.state.location || ``
      filters.theme = location.state.theme || ``
      filters.type = location.state.type || ``

      update(filters)
    }
  }

  filterPieces(pieces, filters) {

    let matchedPieces = [...pieces]

    if (filters.search.length > 0) {
      const options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "title",
          "address.address",
          "artist",
          "themes.name",
          "types.name",
          "collaboration_details",
          "description",
          "location_name",
          "location_notes",
          "city"
        ]
      }

      const fuse = new Fuse(pieces, options);

      matchedPieces = fuse.search(filters.search);
    }

    let matches = matchedPieces.filter(piece => {
      return (
        (filters.location === `` || piece.city === filters.location) &&
        (filters.theme === `` || (piece.themes && piece.themes.filter(theme => theme.name === filters.theme).length > 0)) &&
        (filters.type === `` || (piece.types && piece.types.filter(type => type.name === filters.type).length > 0))
      )
    }
    )
    return matches;
  }

  toggleFilters(e) {
    e.preventDefault()
    this.state.filtersExpanded ?
      this.setState({ filtersExpanded: false })
      : this.setState({ filtersExpanded: true })
  }

  render() {
    const {
      search,
      locations,
      themes,
      types,
      filters,
      update,
      match
    } = this.props

    return (
      <ThemeProvider theme={{ mode: "main" }} location={this.props.location}>
        <Wrapper>
          <StyledHeader noContainer />
          <Main>
            <Left>
              <Toggle
                variant="secondary"
                onClick={this.toggleFilters}
                text="Filters"
                url="#"
                expanded={this.state.filtersExpanded} />
              <StyledFilters
                search={search}
                locations={[``, ...locations]}
                themes={[``, ...themes]}
                types={[``, ...types]}
                selectedLocation={filters.location}
                selectedTheme={filters.theme}
                selectedType={filters.type}
                update={update}
                expanded={this.state.filtersExpanded} />
            </Left>
            <Fragment>
              <Route
                path={match.url + '/list'}
                render={() =>
                  <Right className="right">
                    <Loader loading={this.props.loading}>
                      {this.filterPieces(this.props.pieces, this.props.filters).length === 0 ? (
                        <NoPieces />
                      ) : (
                        <StyledCardGrid
                          cards={
                            buildCardGridArray(
                              this.filterPieces(
                                this.props.pieces,
                                this.props.filters
                              )
                            )
                          }
                          columns={
                            {
                              sm: 2,
                              md: 2,
                              lg: 3,
                              xl: 4
                            }
                          } />
                      )}
                    </Loader>
                  </Right>
                }
              />
              <Route path={`${this.props.match.url}/map/:pieceid?`}
                render={
                  props =>
                    <Right map>
                      <StyledLoader loading={this.props.loading}>
                        {this.filterPieces(this.props.pieces, this.props.filters).length === 0 ? (
                          <NoPieces />
                        ) : (
                          <Map
                            pieces={this.filterPieces(this.props.pieces, this.props.filters)}
                            selected={props.match.params.pieceid} />
                        )}
                      </StyledLoader>
                    </Right>
                }
              />
            </Fragment>
          </Main>
          <StyledFooter noContainer />
        </Wrapper>
      </ThemeProvider>
    )
  }
}

export default Pieces
