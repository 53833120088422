import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

import Address from "./Address"
// import Distance from "./Distance"

const cardVariables = {
  backgroundColor: `${theme.themeColors.themeBase3}`,
  titleColor: `${theme.themeColors.themeTextCommon}`,
  titleSize: `${theme.fontSizes.fontSizeL}`,
  textColor: `${theme.themeColors.themeTextCommon}`,
  boxShadowColor: `${theme.themeColors.themeBase4}`,
  contentPadding: `${theme.padding.inset}`
}

const Card = styled(Link)`
  background-color: ${cardVariables.backgroundColor};
  border-radius: 2px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  color: ${cardVariables.textColor};
  display: block;
  margin: ${theme.margin.stack};
  overflow: hidden;
  text-decoration: none;
  display: flex;
  flex-direction: column;
`

const CardImage = styled.div`
  background-image: url(${props => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 56.25%;
`

CardImage.defaultProps = {
  image: "https://via.placeholder.com/350x150"
}

const CardContent = styled.div`
  padding: ${cardVariables.contentPadding};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Heading = styled.h1`
  color: ${cardVariables.titleColor};
  font-size: ${cardVariables.titleSize};
  font-weight: 300;
  margin: 0;
`

export default props => (
  <Card to={props.url} {...props}>
    <CardImage image={props.image} />
    {props.heading || props.address || props.distance ? (
      <CardContent>
        {props.heading ? <Heading dangerouslySetInnerHTML={{__html: props.heading}}></Heading> : null}
        {props.address ? (
          <Address
            address={props.address}
          />
        ) : null}
        {/* {props.distance ? (
          <Distance
            value={props.distance.value}
            unit={props.distance.unit}
          />
        ) : null} */}
      </CardContent>
    ) : null}
  </Card>
)
