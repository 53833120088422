/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable no-unused-vars*/
import styled from "styled-components"
import theme from "../Theme"
import Container from "./Container"

const footerVariables = {
  backgroundColor: `${theme.themeColors.themePrimary2}`,
  padding: `${theme.spaces.base} 0`,
  textColor: `${theme.themeColors.themeTextEmphasizedInverse}`
}

const Footer = styled.footer`
  background-color: ${footerVariables.backgroundColor};
  color: ${footerVariables.textColor};
  z-index: 1;
`

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  padding: ${footerVariables.padding};
`

const Copyright = styled.small`
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;

  ${theme.media.md`
    flex-basis: auto;
  `}
`

const Disclaimer = styled.p`
  flex-basis: auto;
  font-size: ${theme.fontSizes.fontSizeS};
  
  a {
    color: ${footerVariables.textColor};
  }

  ${theme.media.md`
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    text-align: right;
  `}
`

let now = new Date();
let currentYear = now.getFullYear();

export default props => 
  <Footer className={props.className}>
    {
      props.noContainer ? (
        <FooterContainer>
          <Copyright>&copy; Copyright {currentYear}</Copyright>
          <Disclaimer>This public art directory is owned and maintained by <a href="http://www.onecolumbiasc.com/">One Columbia</a></Disclaimer>
        </FooterContainer>
       )
      : 
      ( <Container>
          <FooterContainer>
            <Copyright>&copy; Copyright {currentYear}</Copyright>
            <Disclaimer>This public art directory is owned and maintained by <a href="http://www.onecolumbiasc.com/">One Columbia</a></Disclaimer>
          </FooterContainer>
        </Container>
      )
    }
  </Footer>