import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import theme from "../Theme"

const LogoLink = styled(Link)`
    align-items: center;
    color: ${theme.themeColors.themeTextEmphasizedInverse};
    display: flex;
    flex-grow: 1;
    font-size: ${theme.fontSizes.fontSizeS};
    margin-right: ${theme.spaces.s};
    padding: ${theme.spaces.s} 0;
    text-decoration: none;
`

const Logo = styled.img`
  height: ${theme.spaces.l};
  margin: ${theme.margin.inlineXS};
  vertical-align: middle;
`

const LogoText = styled.span``

export default props => {
  return (
    <LogoLink to={props.url}>
      <Logo 
        src={props.imageUrl}
        alt={props.imageAltText} /><LogoText>{props.logoText}</LogoText></LogoLink>
  )
}