export default data => {
  let address = data.acf.address.address
  let addressArray = address.split(",");
  let locationTitle, street, city, stateZip;

  if (addressArray.length > 4) {
    locationTitle = addressArray[0];
    street = addressArray[1];
    city = addressArray[2];
    stateZip = addressArray[3];
  } else {
      street = addressArray[0];
      city = addressArray[1];
      stateZip = addressArray[2];
  }

  let image = data._embedded ? data._embedded["wp:featuredmedia"]["0"].media_details.sizes["card"]
  .source_url : null
  
  const cardObject = {
    id: data.id,
    url: `/piece/${data.id}`,
    heading: data.title.rendered,
    address: {
      locationTitle: locationTitle,
      street: street,
      city: city,
      stateZip: stateZip
    },
    distance: {
      value: "2.3",
      unit: "miles"
    },
    image: image      
  }

  return cardObject
}